<template>
  <div class="login">
    Test
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'LoginView'
}
</script>

<style scoped>

</style>
